import React from 'react';

import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { InitialiserProps, WidgetWrapper } from '../../types/InitialiserProps';
import type { Translate } from '../../types/Localiser';
import type { WidgetProps } from '../../types/WidgetProps';

import PromotionMain from './PromotionMain';
import styles from './styles/promotion-fallback.css';

loadCSS('promotionfallback');

interface PromotionFallbackProps {
  translate: Translate;
  widgetType: string;
  params: WidgetProps['params'];
  linkText: string;
}

const PromotionFallback: React.FC<PromotionFallbackProps> = ({
  translate,
  widgetType,
  params,
  linkText,
}) => {
  if (widgetType === 'promotion') {
    return (
      <PromotionMain>
        <p className={styles.message}>{translate('promotionFallbackMessage')}</p>
      </PromotionMain>
    );
  }
  return <>{linkText || (params && params.model_name)}</>;
};

PromotionFallback.getInitialiserProps = async ({
  renderProps: { el, site, articleUrl, ...restRenderProps },
  attributes,
  getWidgetResponse,
  getSeasonalResponse,
  getAiredaleFeeds,
  getModelSuggestions,
  postForWidgetResponse,
  editorial,
  defaultParams,
  url,
  origin,
  dataLinkMerchant,
}): InitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    url,
    origin,
    dataLinkMerchant,
    site,
    getWidgetResponse,
    getSeasonalResponse,
    getAiredaleFeeds,
    getModelSuggestions,
    postForWidgetResponse,
    placeholder: el,
    articleUrl,
    attributes,
    ...restRenderProps,
  });

  return { props, type, widgetWrapper: WidgetWrapper.DIV };
};

export default PromotionFallback;
